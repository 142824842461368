@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro");

.imgContainer {
  position: relative;
  width: 100%;
  max-width: 400px;
  transform: translate(0%, 15%);
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.backgroundImage {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.heading {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.overlayButton {
  font-family: "Source Code Pro", monospace;
  margin: 10px;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  background-color: #4568dc;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  border: 3px solid #283d82;
  position: absolute;
  left: calc(50% - 8px);
  transform: translateX(-50%);
}

.overlayButton:nth-of-type(1) {
  top: 25%;
}

.overlayButton:nth-of-type(2) {
  top: 40%;
}
.overlayButton:hover {
  background: #ffafd0;
  transform: translate(-50%, -2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 3px solid #f681b2;
}

.overlayButton:active {
  background-color: #4568dc;
  transform: translate(-50%, 1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
    .imgContainer {
      transform: translate(0%, 10%);
      max-width: 100%;
    }
  
    .overlayButton {
      padding: 12px 20px;
      font-size: 14px;
      max-width: 80%;
    }
  
    .overlayButton:nth-of-type(1) {
      top: 25%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .imgContainer {
      transform: translate(0%, 5%);
      max-width: 100%;
    }
  
    .heading {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    .overlayButton {
      padding: 10px 15px;
      font-size: 12px;
      max-width: 90%;
    }
  
    .overlayButton:nth-of-type(1) {
      top: 20%;
    }
  }