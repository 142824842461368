.chatroom-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100%;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    overflow: hidden;
  }
  .chat-header {
    padding: 10px;
    font-size: 24px;
    text-align: center;
  }
  
  .chatbox {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  
  .userlist {
    flex: 0.3;
    background-color: #f2f2f2;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e6e6e6;
    overflow-y: auto;
    max-width: 210px;
  }
  
  .userlist-header {
    background: lightgrey;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .user {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .user-count {
    font-size: 16px;
    margin-left: 10px;
  }
  
  .message-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background: linear-gradient(#1c92d2, #f2fcfe);
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .message-content {
    background: #ffafd0;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    overflow-wrap: break-word;
  }
  
  .input-section {
    display: flex;
    border-top: 1px solid #e6e6e6;
    background: white;
    padding: 10px;
  }
  
  .input-field {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
    background: #f2f2f2;
  }
  
  .input-field:focus {
    outline: none;
  }
  
  .send-button {
    width: 50px;
    height: 50px;
    border: none;
    background: #4568dc;
    color: white;
    border-radius: 50%;
    font-size: 20px;
  }
  
  .send-button:focus {
    outline: none;
  }
  
  /* 반응형 디자인 추가 */
  @media (min-width: 768px) {
    .chatroom-container {
      flex-direction: row;
      height: 600px;
      width: 1000px;
      margin: 130px auto;
    }
  
    .userlist {
      flex: 1;
    }
  
    .chatbox {
      flex: 2;
    }
  }
  
  @media (max-width: 767px) {
    .userlist {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      border-left: none;
      border-top: 1px solid #e6e6e6;
    }
  
    .userlist-header {
      font-size: 16px;
      padding: 10px;
      margin-bottom: 0;
    }
  
    .user {
      font-size: 14px;
      border-bottom: none;
    }
  
    .chatroom-container {
      margin-top: 10px;
      height: auto;
    }
  }  