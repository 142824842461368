@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro");
body {
  margin: 0;
  font-family: "Source Code Pro", monospace;
}

.error-container {
  background-image: linear-gradient(to bottom right, #6200c4, #ed6f00);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
}

.error-code {
  font-size: 24vmin;
  font-weight: bold;
  padding: 1.5vmin 5vmin;
  display: flex;
  justify-content: center;
}

.error-code span {
  display: block;
  animation: error-code 1s linear infinite;
}

.error-title {
  font-family: 'Fredoka One', cursive;
  font-size: 2.8rem;
  color: white;
  padding: 1.5vmin 10vmin;
}

.error-message {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  color: white;
  padding: 1.5vmin 30vmin;
}

@keyframes error-code {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1vmin);
  }
  50% {
    transform: translateY(1vmin);
  }
  75% {
    transform: translateY(-1vmin);
  }
  100% {
    transform: translateY(0);
  }
}