@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  background: repeating-linear-gradient(
      45deg,
      #aaa,
      #aaa 2px,
      #ccc 2px,
      #ccc 6px
  ); /* 배경 패턴 적용 */
  font-family: "Source Code Pro", monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ddd;
  border: 2px solid #333;
  box-shadow: 4px 4px #333;
}

.main-custom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background: #ddd;
  color: #333;
}

.main-custom p {
  font-size: 2em;
  color: #333;
  text-shadow: none;
  margin: 20px 0 -10px;
  padding: 100px 0 10px;
  border-bottom: 2px solid #333;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* 버튼 사이의 간격을 20px로 설정 */
  margin-top: 200px;
}

.button-custom {
  padding: 16px 36px 22px;
  border: 2px solid #666;
  background-color: #fff;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  border-radius: 6px;
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
}

.button-custom:focus {
  outline: none;
}

.button-custom:after {
  content: '';
  display: block;
  height: 7px;
  width: 100%;
  background-image: repeating-linear-gradient(
      45deg,
      #666,
      #666 1px,
      transparent 2px,
      transparent 5px
  );
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-top: 1px solid #666;
  position: absolute;
  left: 0;
  bottom: 0;
  background-size: 7px 7px;
}

.button-custom:hover {
  background-color: #666;
  color: #fff;
  border-color: #000;
}

.button-custom:hover:after {
  background-image: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 1px,
      transparent 2px,
      transparent 5px
  );
  border-top: 1px solid #000;
  animation: stripe-slide 12s infinite linear forwards;
}

.button-custom.button-custom--large {
  width: 50%;
}

.button-custom.button-custom--radius {
  border-radius: 36px;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 1024px) {
  .main-custom p {
    font-size: 1.8em;
    padding: 80px 0 8px;
  }

  .button-container {
    flex-direction: column; /* 작은 화면에서는 버튼을 다시 세로로 배치 */
  }

  .button-custom {
    margin: 0; /* button-container의 margin-top에 의해 위치가 조정됨 */
    padding: 14px 32px 20px;
  }

  .button-custom.button-custom--large {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .main-custom p {
    font-size: 1.6em;
    padding: 60px 0 6px;
  }

  .button-custom {
    padding: 12px 28px 18px;
  }

  .button-custom.button-custom--large {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .main-custom p {
    font-size: 1.4em;
    padding: 40px 0 4px;
  }

  .button-custom {
    padding: 10px 24px 16px;
  }

  .button-custom.button-custom--large {
    width: 80%;
  }
}